import * as React from "react"
import { ActionStoreProvider, useActionState, useAuthState } from "../../../services"
import Actions from "../actions"
import NoActions from "../progress/NoActions"
import Alert from "../alert"
import ActionsSkeleton from "../actions/actionsSkeleton"
import { ComponentWithPagination } from "../pagination"

const PendingActionsInit = (props) => {
    const {
        noActionTitle,
        noActionMessage,
        noActionIcon,
        ...rest
    } = props

    const { state, services } = useActionState()
    const { services:authServices } = useAuthState();

    const reloadDataFun = React.useCallback(() => {
        authServices.getPendingActionsCount();
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = () => {
        services.getPendingActions()
    }

    React.useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const data = (rest?.data && rest?.data.length) || state?.pendingActionData || []

    if (state.loading_actions && !data.length) {
        return <ActionsSkeleton sx={{ my: 2 }} />;
    }

    if (state.error) {
        return <Alert type={`alert`} message={state.message} />
    }

    if (!state.loading_actions && !data.length) {
        return (
            <NoActions
                title={noActionTitle ? noActionTitle : "There are no pending actions"}
                // message={noActionMessage ? noActionMessage : "At the moment all the actions are closed..."}
                icon={noActionIcon ? noActionIcon : "noPendingActions"}
            />
        )
    }

    return <ComponentWithPagination
        component={Actions}
        reloadDataFun={reloadDataFun}
        data={data}
    />
}

const PendingActions = React.memo((props) => {
    return (
        <ActionStoreProvider>
            <PendingActionsInit {...props} />
        </ActionStoreProvider>
    )
})

export default PendingActions
