import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Flexbox from '@mui/material/Grid';

import Login from "../modals/Login";

const MyAccountLogin = withSubtheme((props) => {
    return (
        <Flexbox container>
            <Flexbox item xs={12}>
                <Login onClose={() => void 0} />
            </Flexbox>
        </Flexbox>
    );
}, 'loginPage')

export default React.memo(MyAccountLogin);
