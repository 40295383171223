import React, { useState } from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import { doProfileUpdate, getCurrentTab, getTabLists } from '../helper';
import { MYACCOUNT_LIGHT } from "../../../constants/urls";

// @todo Hngh. We need to stop confusing components-mui's Grid, myaccount's Grid and MUI's Grid.
import { Grid, Container } from '@mui/material';

import GlobalLayout from "../GlobalLayout";

import Tabs from '../tabs';
import ProfileCard from '../user/profileCard';
import Profile from '../user/profile';
import ProofOfFunds from '../user/proofofFunds'
import ChangePass from '../user/password';
import NotificationPreference from '../user/notificationPreference';
import PropertyPreferences from '../user/propertyPreference/dialog';
import PropertyPreferenceIndex from '../user/propertyPreference';
import ValuationModule from '../simpleModule/valuation';
import { ProfileStoreProvider, useAuthState } from "../../../services";

import { useSelector, useDispatch } from "react-redux";
import { updateReduxPropertyPreference } from '../../../redux/reducers/propPreferenceSlice';

const ProfileFormSidebar = React.memo(({ showValuationWidget }) => {
    return (
        <Grid container spacing={8}>
            <Grid item xs={12} lg={8}>
                <Profile />
            </Grid>
            {showValuationWidget &&
                <Grid item xs={12} lg={4}>
                    <ValuationModule />
                </Grid>
            }
        </Grid>
    )
})

const ChangePassFormSidebar = React.memo(({ showValuationWidget }) => {
    return (
        <Grid container spacing={8}>
            <Grid item xs={12} lg={8}>
                <ChangePass />
            </Grid>
            {showValuationWidget &&
                <Grid item xs={12} lg={4}>
                    <ValuationModule />
                </Grid>
            }
        </Grid>
    )
})

const NotificationFormSidebar = React.memo(({ showValuationWidget }) => {
    return (
        <Grid container spacing={8}>
            <Grid item xs={12} lg={8}>
                <NotificationPreference />
            </Grid>
            {showValuationWidget &&
                <Grid item xs={12} lg={4}>
                    <ValuationModule />
                </Grid>
            }
        </Grid>
    )
})

const PropertyPreferencesFormSidebar = React.memo(() => {
    return (
        <Grid container spacing={8}>
            <Grid item xs={12} lg={12}>
                <PropertyPreferenceIndex />
            </Grid>
        </Grid>
    )
});

const ProofOfFundsFormSidebar = React.memo(() => {
    const { state, services } = useAuthState();
    return (
        <Grid container spacing={8}>
            <Grid item xs={12} lg={12}>
                <ProofOfFunds state={state} services={services} />
            </Grid>
        </Grid>
    )
});

const defaultTabItems = {
    'personal-details': {},
    'property-preference': {},
    'notification-preference': {},
    'change-password': {},
    'logout':{}
};

const defaultTabItem = 'personal-details';

const TabComp = (props) => {
    // tabItems Can be Array or Objects
    // const _tabItems = ['personal-details', 'property-preference', 'notification-preference', 'change-password', 'logout'];

    const tabItems = props?.tab?.items || defaultTabItems;
    const defaultItem = props?.tab?.default || defaultTabItem;

    const currentTab = getCurrentTab(defaultItem, tabItems);
    const [value] = React.useState(currentTab);

    const dispatch = useDispatch();
    const user = useSelector(state => state.profile);
    const preference = useSelector(state => state.propertyPreference);

    let availableTabs = {
        "personal-details": {
            "id": "personal-details",
            "name": "Personal Details",
            "content": <ProfileFormSidebar showValuationWidget={props?.showValuationWidget} />,
        }
    }

    if (!MYACCOUNT_LIGHT) {
        availableTabs["property-preference"] =  {
            "id": "property-preference",
            "name": "Property Preference",
            "content": <PropertyPreferencesFormSidebar />,
        }
    }

    availableTabs["notification-preference"] =  {
        "id": "notification-preference",
        "name": "Notification Preferences",
        "content": <NotificationFormSidebar showValuationWidget={props?.showValuationWidget} />,
    }

    availableTabs["change-password"] =  {
        "id": "change-password",
        "name": "Change Password",
        "content": <ChangePassFormSidebar showValuationWidget={props?.showValuationWidget} />
    }

    availableTabs["proof-of-funds"] =  {
        "id": "proof-of-funds",
        "name": "Proof of Funds",
        "content": <ProofOfFundsFormSidebar showValuationWidget={props?.showValuationWidget} />
    }

    const data = React.useMemo(() => {
        return getTabLists(availableTabs, tabItems);
    }, [tabItems]);

    const { services, state } = useAuthState();
    // const [openModal, setOpenModal] = React.useState(false);
    // const [hasPersona, setHasPersona] = useState(true);

    const pendingItemsCountCall = state.pendingItemsCountCall;

    const getData = async () => {
        try {
            const data = await services.hasPersona();
            // setHasPersona(data);
            // update has persona here...
            dispatch(updateReduxPropertyPreference(!data));
            
        } catch (e) {
            console.log("Error => ", e.response)
            if (e?.response?.status === 401) {
                services.logout()
            }
        }
    }

    // React.useEffect(() => {
    //     if(user) {
    //         let modalStatus = false;
    //         if (!hasPersona || doProfileUpdate(user.profile)) {
    //             modalStatus = true;
    //         }

    //         dispatch(updateReduxPropertyPreference(modalStatus));
    //     }
    // }, [user, hasPersona]);

    React.useEffect(/*async*/() => {
        services.updateState({pageTitle:`Profile`, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: false});
        // dispatch(updateReduxPropertyPreference(false));

        if (!MYACCOUNT_LIGHT)
            getData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // React.useMemo(() => {
    //     setOpenModal(preference.showModal);
    // }, [preference?.showModal]);

    // ref: https://github.com/facebook/react/issues/15156 Option 3
    return React.useMemo(() => {
        return (
            <>
                <Tabs
                    data={data}
                    manageValue={value}
                    tabItems={tabItems}
                    addUrl={true}
                    altClassName={`profile-tabs`}
                />
                {(!!preference.showModal) && (
                    <PropertyPreferences
                        conversationalFormEnabled={props.conversationalFormEnabled}
                    />
                )}
            </>
        )
    }, [/*pendingItemsCountCall, */preference.showModal]) // eslint-disable-line react-hooks/exhaustive-deps
}

// const mapStateToProps = ({ propertyPreference, profile }) => ({
//     showModal: propertyPreference.showModal,
//     user: profile
// });

// const TabComp = connect(mapStateToProps)(TabComponent);

const MyAccountInboxInner = withSubtheme((props) => {
    return (
        <Container maxWidth="xl">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ProfileCard />
                </Grid>
                <Grid item xs={12}>
                    <ProfileStoreProvider>
                        <TabComp
                            tab={props?.tab}
                            showValuationWidget={props?.showValuationWidget}
                            conversationalFormEnabled={
                                props.conversationalFormEnabled
                            }
                        />
                    </ProfileStoreProvider>
                </Grid>
            </Grid>
        </Container>
    );
}, 'profilePage')

const MyAccountInbox = () => {
    return (
        <GlobalLayout>
            <MyAccountInboxInner />
        </GlobalLayout>
    )
}

export default React.memo(MyAccountInbox);
