import React from 'react';
import { withSubtheme } from "../../../../StarberryComponentsMui"
import ActionModalDefault from '../../modals/default';
import PropertyPreferenceDialog from './dialogContent';
import CircularProgress from '../../progress/CircularProgress'
import { useProfileState } from "../../../../services";
import defaults from "./dialogDefaults"

const PropertyPreferences = withSubtheme(props => {
    // popup
    const { conversationalFormEnabled, forceRegistration, dialogTitle } = props;

    const [open, setOpen] = React.useState(true);
    const { state } = useProfileState()
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value, reason) => {
        value.preventDefault();
        if (reason !== 'backdropClick') {
            setOpen(false)
        }
    };

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = (
            <PropertyPreferenceDialog
                conversationalFormEnabled={conversationalFormEnabled}
                handleClose={handleClose}
                modal={true}
            />
        );
    }
    // React.useEffect(() => {
    //     // services.getUserProfile()
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let dialogAddtionalProps = {}
    if ((state.preference_success && !state.error) || !forceRegistration)
        dialogAddtionalProps = { handleClose }

    return (
        <>
            {state.loading ? (
                <ActionModalDefault
                    open={open}
                    children={<CircularProgress />}
                    loading={true}
                />
            ) : (
                <ActionModalDefault
                    open={open}
                    handleClickOpen={handleClickOpen}
                    children={dialogContent}
                    title={dialogTitle}
                    maxDialogWidth={`sm`}
                    {...dialogAddtionalProps}
                />
            )}
        </>
    )
}, "dialogPreference", defaults)

export default PropertyPreferences
