export default {
    "props": {

    },
    "sx": {
        "& .userAvatar-avatar": {
            "margin": 0,
            // "backgroundColor": "#ff0000",
            "width": 80,
            "height": 80,
            "textTransform": "uppercase",
        },
        "& .userAvatar-smallAvatar": {
            "margin": 0,
            "backgroundColor": "#3d3d3d",
            "border": "1px solid #fff",
            "width": 20,
            "height": 20,
        },
        "& .userAvatar-smallAvatarIcon": {
            "margin": 0,
            // "backgroundColor": "#3d3d3d",
            "width": 12,
            "height": 12,
        },
    },
}