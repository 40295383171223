import React from 'react';
import MyAccountIndex from '../containers/index';
import { MyaccountStoreProvider } from '../../../services';
import GlobalLayout from "../GlobalLayout";

const MyAccountIndex2Page = () => {
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <MyAccountIndex />
            </MyaccountStoreProvider>
        </GlobalLayout>
    );
}

export default React.memo(MyAccountIndex2Page);
