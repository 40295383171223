import * as React from 'react';
import UserChangePasswordComponent from './password';

const UserChangePassword = props => {
    return (
        <UserChangePasswordComponent props={props} />
    )
}

export default UserChangePassword;
