import React from 'react';
import MyAccountInbox from '../containers/inbox';
import { MyaccountStoreProvider } from '../../../services';
import GlobalLayout from "../GlobalLayout"

const MyAccountInboxPage = () => {
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <MyAccountInbox />
            </MyaccountStoreProvider>
        </GlobalLayout>
    );
}

export default React.memo(MyAccountInboxPage);
