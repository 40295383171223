import * as React from 'react';
import UserProfileComponent from './profile';

const UserProfile = (props) => {
    return (
        <UserProfileComponent props={props} />
    )
}

export default React.memo(UserProfile);
