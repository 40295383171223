export default {
    "props": {
        "maxDialogW": "sm",
        "fabProps": {
            "color": "primary",
            "variant": "extended",
        }
    },
    "sx": (theme) => ({
        "width": "100%",
        "marginBottom": theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            "width": "auto",
            "minWidth": "300px",
        },
    }),
}
