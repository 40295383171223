import React from 'react';
import { FormStoreProvider, ProfileStoreProvider, useProfileState } from "../../../../services";
import ComponentLayout from '../../ComponentLayout';
import NoActions from "../../progress/NoActions";
import Actions from '../../actions';
import NewDocumentBtn from './newBtn';
import ActionSkeletonWithMore from "../../actions/actionSkeletonWithMore"

const DocumentContent = (props) => {

    const { state, services } = useProfileState();

    const offerData = state?.identityDocuments || [];

    const noRecord = (!state.loading && !offerData.length);
    const propertyImage = '';

    const getData = () => {
        services.getIdentityDocuments();
    }

    const reloadDataFun = () => {
        getData()
    }

    // fun
    React.useEffect(() => {
        services.getIdentityDocuments();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <ComponentLayout>
            <FormStoreProvider>
                <NewDocumentBtn reloadDataFun={reloadDataFun} />
            </FormStoreProvider>
            { state.loading && !offerData.length ? (
                <ActionSkeletonWithMore title />
            ) : (
                <React.Fragment>
                    {noRecord && (
                        <NoActions
                            title="There are no Documents"
                            icon="documentsIcon"
                        />
                    )}
                    {!noRecord && (
                        <Actions
                            title="PROOF OF FUNDS"
                            data={offerData}
                            showActionButton={false}
                            image={propertyImage}
                        />
                    )}
                </React.Fragment>
            )}

        </ComponentLayout>
    );
}

const Document = () => {
    return (
        <ProfileStoreProvider>
            <DocumentContent />
        </ProfileStoreProvider>
    )
}

export default Document;
