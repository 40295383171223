import React from 'react';
import MyAccountCalendar from '../containers/calendar';
import { MyaccountStoreProvider } from "../../../services"
import GlobalLayout from "../GlobalLayout"

const MyAccountCalendarPage = () => {
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <MyAccountCalendar />
            </MyaccountStoreProvider>
        </GlobalLayout>
    );
}

export default React.memo(MyAccountCalendarPage);
