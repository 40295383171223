export default {
    "sx": (theme) => ({
        "& .linkBackToWebsite": {
            "position": "absolute",
            "left": "10px",
            "top": "10px",
            "& .desktop": {
                "display": "none",
            },
            [theme.breakpoints.up('sm')]: {
                "left": "20px",
                "top": "20px",
                "& .desktop": {
                    "display": "inline-block",
                },
                "& .mobile": {
                    "display": "none",
                }
            },
            "& a": {
                "display": "flex",
                "fontSize": theme.bodySmall,
                "alignItems": "center",
                "flexDirection": "row",
                "& svg": {
                    "marginRight": "5px",
                    "width": "14px",
                    "height": "14px"
                }
            }
        },
    })
}