export default {
    "props": {
        "cardProps": {
            // "variant": "outlined",
            "elevation": 0,
        },
    },
    "sx": (theme) => ({
        "marginBottom": theme.spacing(3.75),
        [theme.breakpoints.up('sm')]: {
            "marginBottom": theme.spacing(4),
        },
        [theme.breakpoints.up('lg')]: {
            "marginBottom": theme.spacing(8),
        },
        "& .welcomeCard-cardHeader": {
            "padding": 0,
            "& .welcomeCard-avatar": {
                "marginRight": theme.spacing(2.5),
                [theme.breakpoints.up('sm')]: {
                    "marginRight": theme.spacing(3.75),
                },
                [theme.breakpoints.up('lg')]: {
                    "display": "none",
                },
            },
            "& .welcomeCard-title": {
                "fontSize": theme.h5,
                "lineHeight": theme.h5LineHm,
                "fontWeight": "normal",
                "textTransform": "capitalize",
                [theme.breakpoints.up('sm')]: {
                    "fontSize": theme.h3,
                    "lineHeight": theme.h3LineHm,
                }
            },
            "& .welcomeCard-subheader": {
                "color": theme.palette.text.primary,
            },
        },
        "& .welcomeCard-cardHeaderCaption": {
            "color": theme.palette.grey.grey2,
            [theme.breakpoints.up('lg')]: {
                "display": "none",
            }
        },
        "& .welcomeCard-welcomeAvatar": {
            "display": "none",
            // "margin": 0,
            // // "backgroundColor": "#ff0000",
            // "width": theme.spacing(10),
            // "height": theme.spacing(10),
        },
        "& .welcomeCard-welcomeAvatar-img": {

            // "margin": 0,
            // "backgroundColor": "#ff0000",
            "width": theme.spacing(10),
            "height": theme.spacing(10),
        },
    }),
}
