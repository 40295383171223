export default {
    "props": {
        "dialogContentTextProps": {
            title: `A valid current Proof of Funds is required. <br />
            If you are mortgage approved please upload your AIP in date, with date and amount clearly visible.
            If you are a cash buyer please upload an up to date image/screenshot of your cash funds. Name on the account must be the same as the buyers (your) name.
            If the bid is a combination of gift letter and cash or AIP, please upload the gift letter and either AIP or Cash buyer proof as above
            `
        },
        "btnLabel": "ADD PROOF OF FUNDS",
        "showPlusBtn" : true,
        "btnProps": {
            // "color": "secondary",
            "variant": "contained",
            "sx": (theme) => ({
                "width": "100%",
                "marginBottom": theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    "maxWidth": "300px",
                    "width": "100%",
                }
            })
        }
    }
}
