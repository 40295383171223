import * as React from 'react';
import UserNotificationPreferences from './notificationPreference';

const NotificationPreferences = props => {
    return (
        <UserNotificationPreferences props={props} />
    )
}

export default NotificationPreferences;
