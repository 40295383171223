import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Grid from '@mui/material/Grid';
import SignUp from "../modals/SignUp";

const MyAccountLogin = withSubtheme((props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <SignUp onClose={() => void 0} />
            </Grid>
        </Grid>
    )
}, 'signUpPage')

export default React.memo(MyAccountLogin);
