export default {
    "props": {
       
    },
    "sx": (theme) => ({
        "& .MuiAlert-standard": {
            "marginBottom": theme.spacing(4),
        },
        "& .globalForm-actionContainer": {
            "& .globalForm-formButton": {
                "width": "50%",
            },
        }
    }),
}