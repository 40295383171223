import * as React from "react"
import { ActivityStoreProvider, useActivityState } from "../../../services"
import Activities from "../activities"
import NoActions from "../progress/NoActions"
import Alert from "../alert"
import RecentActivitiesSkeleton from "./recentActivitiesSkeleton"
import { ComponentWithPagination } from "../pagination"

const RecentActivityInit = ({ props }) => {
    const { state, services } = useActivityState()

    React.useEffect(() => {
        services.getActivities()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const data = (props?.data || state?.activitiesData || []);

    if (state.loading_activities && !data.length) {
        return <RecentActivitiesSkeleton />
    }

    if (state.error) {
        return <Alert type={`alert`} message={state.message} />
    }

    if (!state.loading_activities && !data.length) {
        return (
            <NoActions
                title="There are no recent activities"
                // message="At the moment all the actions are closed..."
                icon="recentActivitiesIcon"
            />
        )
    }

    return <ComponentWithPagination
        {...props}
        component={Activities}
        data={data}
    />
}

const RecentActivity = React.memo((props) => {
    return (
        <ActivityStoreProvider>
            <RecentActivityInit props={props} />
        </ActivityStoreProvider>
    )
})

export default RecentActivity
