import * as React from 'react';
import { withSubtheme } from '../../../../StarberryComponentsMui';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import UserAvatar from '../../user/avatar';

import defaults from './defaults';

import { connect } from 'react-redux';

const ProfileCard = withSubtheme((props) => {
    const {
        cardProps,
        className,
    } = props

    // fetch data redux from store
    const user = props?.user || {};

    let displayName = `Welcome ${user.name}`;
    let avatarClass = `profileCard-avatar`;
    if (!!user.picture) {
        displayName = `${user.name}`;
        avatarClass = `profileCard-avatar-img`;
    }

    return(
        <Card
            classes={{root: className}}
            {...cardProps}
        >
            <CardHeader
                avatar={<>
                        {!!user.picture && (
                            <UserAvatar
                                aria-label={user.name}
                                alt={user.name}
                                src={user.picture}
                                sizes=""
                            />
                        )}
                    </>
                }
                title={displayName}
                titleTypographyProps={{"variant": "h3"}}
                subheaderTypographyProps={{"variant": "bodyLarge"}}
                subheader={user.job_title}
                classes={{
                    root: "profileCard-header",
                    avatar: `${avatarClass}`,
                    title: "profileCard-title",
                    subheader: "profileCard-subheader",
                }}
            />
        </Card>
    )
}, "profileCard", defaults)

const mapStateToProps = ({ profile }) => ({
    user: profile.user
});

const ProfileCardComponent = connect(mapStateToProps)(ProfileCard);

export default React.memo(ProfileCardComponent)
